<script>

  import { API_ROOT } from '../config';
  import { API_TOKEN } from '../config';
  
  import { reactive, defineComponent } from 'vue';
  import MonthNav from '../components/MonthNav.vue';
  import BudgetItemGrid from '../components/BudgetItemGrid.vue';  


  export default defineComponent({
    name: "MonthView",
    components: {
      BudgetItemGrid,
      MonthNav
    },
    props: {
      month: Number,
      year: Number
    },
    data() {

      return {
        expenses: reactive([]),
        savings: reactive([]),
        incomes: reactive([]),
        loading: true,
        selected_month: this.month - 1,
        selected_year: this.year,
        expense_categories: [] 
      };
    },
    methods: {

      getExpenseCategories() {
        this.loading = true;

        const request_data = {
          method: "GET",
          headers: new Headers({
            'Content-Type': 'application/json',
            'x-api-key': API_TOKEN
          }),
          mode: 'cors',
        };

        fetch(API_ROOT + '/expense_categories', request_data)
          .then(result => result.json())
          .then(data => {
            let other_index = data.findIndex(x => x['name'] == 'other')
            if (other_index) { data.unshift(data.splice(other_index, 1)[0]); }
            this.expense_categories = data

          })
      },

      getTransactions() {
        this.loading = true;

        const request_data = {
          method: "GET",
          headers: new Headers({
            'Content-Type': 'application/json',
            'x-api-key': API_TOKEN
          }),
          mode: 'cors',
        };
        let mm = String(this.selected_month + 1).padStart(2, "0")
        let yyyy = String(this.selected_year)
        var query = '?month=' + (mm) + '&year=' + (yyyy);
        fetch(API_ROOT + '/transactions/' + query, request_data)
          .then(result => result.json())
          .then(data => data = this.sortTransactions(data))
          .then(remoteRowData => {
            this.expenses = []
            this.incomes = []
            this.savings = []

            remoteRowData.forEach((elem) => {
              if ("transaction_category" in elem){
                if (elem['transaction_category'] == 'saving') {
                  this.savings.push(elem);
                }
                else if (elem['transaction_category'].includes('expense')) {
                  this.expenses.push(elem);
                }
                else if (elem['transaction_category'] == 'income') {
                  this.incomes.push(elem)
                }
              }
              else {
                console.log(elem)
              }
            });

            this.loading = false;
          })
          .catch(err => { alert(err); this.loading = false; });
          
      },
      sortTransactions (rowData) {
        if (rowData) {
          rowData.sort(function(a, b) {
            let a_date = Date.parse(a.authorized_date ? a.authorized_date : a.date);
            let b_date = Date.parse(b.authorized_date ? b.authorized_date : b.date);
            if (a_date < b_date) {
              return -1;
            }
            else if (a_date > b_date) {
              return 1
            }
            return 0;
          });
        }
        return rowData
      },
    },
    created() {
      this.getExpenseCategories();
      this.getTransactions();
    },
    watch: {
      month(newmonth) {
        this.selected_month = newmonth - 1;
        this.getTransactions();
      },
      year(newyear) {
        this.selected_year = newyear;
        this.getTransactions();
      }
    }
  });
  </script>

<template>
  <div>
    <MonthNav
      :selected_month="selected_month" 
      :selected_year="selected_year" 
    />

    <BudgetItemGrid
      heading="income"
      :data="incomes"
      :loading="loading"
    />
    <BudgetItemGrid
      heading="expense"
      :data="expenses"
      :loading="loading"
      :expense_categories="expense_categories"
    />
    <BudgetItemGrid
      heading="saving"
      :data="savings"
      :loading="loading"
    />
  </div>
</template>
