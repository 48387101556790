<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>  
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'App',
  data() {
    return {
      //
    }
  }
})
</script>

<style>
#app {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}
</style>
