<template>
    <div class="budget-item-grid">
        <h4 class="budget-item-grid-title">{{heading}}</h4>
        <MDBModal
            id="exampleModal"
            tabindex="-1"
            labelledby="detailViewModal"
            v-model="show_modal"
            size="lg"
        >
            <MDBModalHeader>
                <MDBModalTitle id="detailViewModalLabel">{{selected_transaction.name}}</MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
                <JsonViewer
                :value=selected_transaction
                :expand-depth=2
                copyable
                expanded
                >
                </JsonViewer>
            </MDBModalBody>
        </MDBModal>
        <div style="display: flex; flex-direction: column; height: 100%">
            <div style="width: 100%; flex: 1 1 auto;">
                <ag-grid-vue
                    :style="{ height }"
                    class="ag-theme-alpine"
                    :columnDefs="columnDefs"
                    :rowData="griddata"
                    rowSelection="multiple"
                    @gridReady="onGridReady"
                    @cellValueChanged="handleCellValueChanged"
                    :overlayLoadingTemplate="overlayLoadingTemplate"
                    @selection-changed="onSelectionChanged"
                >
                </ag-grid-vue> 
            </div>
            </div>
        </div>
</template>

<script>
    import { API_ROOT } from '../config';
    import { API_TOKEN } from '../config';
    import "ag-grid-community/dist/styles/ag-grid.css";
    import "ag-grid-community/dist/styles/ag-theme-alpine.css";
    import { AgGridVue } from "ag-grid-vue3";
    import { ref } from "vue"
    import {
        MDBModal,
        MDBModalHeader,
        MDBModalTitle,
        MDBModalBody,
    } from 'mdb-vue-ui-kit';
    import 'vue-json-viewer/style.css'
    import JsonViewer from 'vue-json-viewer/ssr';

let rowHeight = 42;

let getName = params => {
    return params.data.edited_name ? params.data.edited_name : params.data.name
}

let getAmount = params => {
    
    let display_amount = params.data.edited_amount ? params.data.edited_amount : params.data.amount;
    console.log(display_amount)
    return '$' + Number(display_amount).toFixed(2)
}

let getDate = params => {
    let date = '';
    if (params.data.edited_date) {
        date = params.data.edited_date
    }
    else if (params.data.authorized_date) {
        date = params.data.authorized_date
    }
    else if (params.data.date) {
        date = params.data.date
    }
    return date.slice(-2, date.length)
}

export default {
    name: "BudgetItemGrid",
    props: {
        expense_categories: Array,
        data: Array,
        loading: Boolean,
        heading: String,
    },
    components: {
      AgGridVue,
      MDBModal,
      MDBModalBody,
      MDBModalHeader,
      MDBModalTitle,
      JsonViewer,
    },
    data() {
        return {
            griddata: [],
            gridApi: ref(null),
            columnApi: ref(null),
            json_detail_item: null,
            height: "200px", 
            show_modal: false,   
            overlayLoadingTemplate: null,
            shiftDown: null,
            deleteDown: null,
            
            columnDefs: [
                { 
                    colId: 'date',
                    headerName: 'Day',
                    valueGetter: getDate, 
                    sortingOrder: ["desc", "asc"], 
                    sortable: true, 
                    filter: true,
                    flex: 1,
                    editable: true,
                },
                { 
                    field: 'edited_name',
                    headerName: "Name",
                    valueGetter: getName,
                    sortable: true, 
                    filter: true, 
                    resizable: true, 
                    flex: 7, 
                    editable: true,
                },
                { 
                    colId: 'amount',
                    headerName: 'Amount',
                    field: 'edited_amount',
                    valueGetter: getAmount, 
                    sortable: true, 
                    filter: true, 
                    resizable: true, 
                    flex: 2, 
                    editable: true,
                    comparator: (valueA, valueB) => {
                        if (valueA == valueB) return 0;
                        return (Number(valueA.replace(/[^0-9.-]+/g,"")) > Number(valueB.replace(/[^0-9.-]+/g,""))) ? 1 : -1;
                    }
                },
                { 
                    colId: 'expense_category',
                    field: 'expense_category', 
                    headerName: 'Category',
                    sortable: true, 
                    filter: true,
                    cellEditor: 'agSelectCellEditor',
                    cellEditorParams: { values: this.expense_categories },
                    flex: 3, 
                    editable: true, 
                },
                { 
                    colId: 'bank',
                    headerName: 'Bank',
                    field: 'institution_name',
                    sortable: true, 
                    filter: true, 
                    resizable: true, 
                    flex: 2, 
                    editable: true,
                },
                /*{ 
                    field: 'transaction_category', 
                    sortable: true, 
                    filter: true, 
                    flex: 2, 
                    editable: true, 
                    singleClickEdit: true, 
                },*/
                
            ],
        }
    },
    methods: {
        onSelectionChanged() {
            const selectedRows = this.gridApi.getSelectedRows();
            this.selected_transaction =
                selectedRows.length === 1 ? selectedRows[0] : null;
        },
        handleCellValueChanged(params) {
            const request_data = {
                method: "PATCH",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'x-api-key': API_TOKEN
                }),
                mode: 'cors',
                body: JSON.stringify(params.node.data),
            };

            fetch(API_ROOT + '/transactions/', request_data)
                .then(result => result.json())
                .catch(err => { alert("Failed to update, check console"); console.log(err) });
        },
        deleteTransaction(transaction) {
            const request_data = {
                method: "DELETE",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'x-api-key': API_TOKEN
                }),
                mode: 'cors',
                body: JSON.stringify(transaction)
            };
            
            fetch(API_ROOT + '/transactions/', request_data)
                .then(() => {
                    let index = this.griddata.findIndex(x => x['transaction_id'] == transaction['transaction_id'])
                    console.log(index)
                    if (index >= 0){
                        this.griddata.splice(index, 1)
                        this.calculateHeightAndTotal(); 
                    }
                    this.selected_transaction = null
                })
                .catch(err => { alert("Failed to delete, check console"); console.log(err) });
        },
        showDetailView() {
            this.show_modal = true;
        },
        closeModal() {
            this.show_modal = false;
        },
        onGridReady(params) {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;
            //this.gridApi.sizeColumnsToFit();
        },
        generatePinnedBottomData() {
            // generate a row-data with null values
            let result = {};
            this.gridColumnApi.getAllGridColumns().forEach(item => {
                result[item.colId] = null;
            });
            return this.calculatePinnedBottomData(result);
        },
        calculatePinnedBottomData(target) {
            //**list of columns fo aggregation**
            let columnsWithAggregation = ['amount']
            columnsWithAggregation.forEach(element => {
                this.gridApi.forEachNodeAfterFilter((rowNode) => {
                    if (rowNode.data[element])
                        target[element] += Number(rowNode.data[element].toFixed(2));
                });
                if (target[element]) {
                    target[element] = `${target[element].toFixed(2)}`;
                }
                else {
                    target[element] = 0;
                }
            })
            return target;
        },
        calculateHeightAndTotal() {
            this.height = (rowHeight * (this.griddata.length + 1) + 67).toString() + "px";
            let pinnedBottomData = this.generatePinnedBottomData();
            this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
        }
    },
    watch: {
        data: function (newData) {
            this.griddata = newData;
            this.calculateHeightAndTotal()
        },
        loading: function (newValue) {
            
            if (newValue) {
                this.gridApi && this.gridApi.showLoadingOverlay();
            }
            else {
                this.gridApi && this.gridApi.hideOverlay();
            }
        },
        expense_categories: function (newval) {
            if (newval) {
                if (this.gridApi) {
                    var columnDefs = this.gridApi.getColumnDefs();
                    columnDefs.forEach(function(colDef) {
                        if (colDef['colId'] == 'expense_category') {
                            colDef.cellEditorParams.values = newval.map(x => x.name)
                            colDef.valueGetter = (params) => {
                                let category = newval.find((x) => x.name == params.data.expense_category)
                                return category ? category.display_name : params.data.expense_category
                            }
                        }
                    })
                    this.gridApi.setColumnDefs(columnDefs)
                }
            }
        }
    },
    updated() {
        if (this.loading) {
            this.gridApi && this.gridApi.showLoadingOverlay();
        }
        else {
            this.gridApi && this.gridApi && this.gridApi.hideOverlay();
        }
        this.calculateHeightAndTotal()
        
    },
    created() {
        this.overlayLoadingTemplate =
            '<span class="ag-overlay-loading-center">Getting transactions...</span>';
        
        window.addEventListener('keydown', (e) => {

            if (e.key == 'Escape') {
                if (this.selected_transaction) {
                    this.showDetailView();
                }
                e.stopPropagation();
            }
            else if (e.key == 'Delete') {
                this.deleteDown = true;
            }
            else if (e.key == 'Shift') {
                this.shiftDown = true;
            }

            if (this.deleteDown && this.shiftDown && this.selected_transaction) {
                e.preventDefault();
                e.stopPropagation();
                this.deleteTransaction(this.selected_transaction)
            }

        });

        window.addEventListener('keyup', (e) => {

            if (e.key == 'Delete') {
                this.deleteDown = false;
            }
            else if (e.key == 'Shift') {
                this.shiftDown = false;
            }
            
        });
    }

}
</script>

<style scoped>
    .budget-item-grid {
        margin-bottom: 40px;
        margin-top: 10px;   
    }
    .budget-item-grid-title {
        margin-left: 50px;
    }
</style>