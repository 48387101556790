<template>
    <div>
        <h1>Welcome back!</h1>
    </div>
  </template>
  <style scoped></style>
  <script>
  </script>
  
  
  <script>
    
    import { defineComponent } from 'vue';
    import { API_ROOT } from '../config';
    import { API_TOKEN } from '../config';
    import { get_url_root } from '../config';
    
    export default defineComponent({
      name: "LinkOauthReturnView",
      components: {
      },
      data() {
 
        },
        methods: {

        },
        created() {
        },
    });
    </script>
  