<template>
  <div>
    {{ plaid }}
    </div>
</template>
<style scoped></style>
<script>
</script>


<script>
  
  import { defineComponent } from 'vue';
  import { API_ROOT } from '../config';
  import { API_TOKEN } from '../config';
  import { get_url_root } from '../config';


  export default defineComponent({
    name: "LinkView",
    components: {
    },
    data() {
        console.log(window.Plaid)
      return {
        plaid: window.Plaid,
      };
    },
    methods: {
        getToken(onSuccess) {
            const request_data = {
            method: "GET",
            headers: new Headers({
                'Content-Type': 'application/json',
                'x-api-key': API_TOKEN
            }),
            mode: 'cors',
            };

            fetch(API_ROOT + '/link', request_data)
            .then(result => result.json())
            .then(data => {
                onSuccess(data)
            })
        },
        saveToken(metadata) {
            const request_data = {
                method: "POST",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'x-api-key': API_TOKEN
                }),
                mode: 'cors',
                body: JSON.stringify(metadata)
            };

            fetch(API_ROOT + '/link', request_data)
            .then(result => result.json())
            .then(data => {
                alert(data);
                this.$router.push("/")
            })
        }
    },
    created() {
        this.getToken((data) => { 
            this.plaid.create({
                token: data['link_token'],
                onSuccess: (public_token, metadata) => { this.saveToken(metadata) },
                onExit: (err, metadata) =>  { alert(err); console.log(err); console.log(metadata) },
                //required for OAuth; if not using OAuth, set to null or omit:
                //receivedRedirectUri: get_url_root() + '/link-oauth-return',
            }).open();
        })
    },
  });
  </script>
