<script>
  
  import { defineComponent } from 'vue';


  export default defineComponent({
    name: "HomeView",
    components: {
    },
    data() {
      
      let today = new Date();

      return {
        today: today,
      };
    },
    methods: {},
    created() {},
  });
  </script>
<template>
  <div>
    <h1>Welcome Home!</h1>

    <p>Todays date is: {{today}}</p>
  </div>
</template>
